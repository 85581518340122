import { LoanSelectionProps, InputNames } from 'contexts/LoanSelectionContext';
import { LOAN_TYPES } from 'lib/constants/offerFilter';
import { OfferSorted } from 'lib/offers/types';
import { getEffectiveInterestRate } from './filterOffers';

export const pickBestOfferForEachBank = (
  purpose: LoanSelectionProps[InputNames.Purpose],
  offers: Record<string, OfferSorted[]> | undefined,
) => {
  if (!offers) {
    return {};
  }

  return Object.keys(offers).reduce(
    (state: Record<string | number, unknown>, key) => {
      if (offers[key].length === 1) {
        state[key] = offers[key][0];
        return state;
      }

      // NOTE: sort and return only the first/best one for each bank
      state[key] =
        purpose === 'new_car' || purpose === 'pre_owned_car'
          ? offers[key].sort(sortForCarPurpose)[0]
          : offers[key].sort(sortForInterestRate)[0];

      return state;
    },
    {},
  );
};

export const sortForCarPurpose = (
  offerSortedItemA: OfferSorted,
  offerSortedItemB: OfferSorted,
) => {
  const combination = [
    getCarPriorities(offerSortedItemA.productType),
    getCarPriorities(offerSortedItemB.productType),
  ].join('_');

  return evaluateProductPriorities(
    offerSortedItemA,
    offerSortedItemB,
    combination,
  );
};

export const getCarPriorities = (type: string) => {
  switch (type) {
    case LOAN_TYPES.car:
      return 'CAR';
    case LOAN_TYPES.installment:
      return 'INSTALLMENT';
    default:
      return 'OTHER';
  }
};

export const evaluateProductPriorities = (
  offerSortedItemA: OfferSorted,
  offerSortedItemB: OfferSorted,
  combination: string,
) => {
  switch (combination) {
    case 'CAR_CAR':
      return sortForInterestRate(offerSortedItemA, offerSortedItemB);
    case 'CAR_INSTALLMENT':
    case 'CAR_OTHER':
      return -1;
    case 'INSTALLMENT_CAR':
    case 'OTHER_CAR':
      return 1;
    case 'INSTALLMENT_OTHER':
      return -1;
    case 'OTHER_INSTALLMENT':
      return 1;
    default:
      // case 'INSTALLMENT_INSTALLMENT' && 'OTHER_OTHER'
      return offerSortedItemA.id - offerSortedItemB.id;
  }
};

export const sortForInterestRate = (
  offerSortedItemA: OfferSorted,
  offerSortedItemB: OfferSorted,
) => {
  const interestDiff =
    getEffectiveInterestRate(offerSortedItemA) -
    getEffectiveInterestRate(offerSortedItemB);
  if (interestDiff !== 0) {
    return interestDiff;
  }

  const combination = [
    getInterestPriorities(offerSortedItemA.productType),
    getInterestPriorities(offerSortedItemB.productType),
  ].join('_');

  // NOTE: sorting [a, b]: -1 is [a, b] -- 1 is [b, a] -- 0 is equals
  // see also array.sort
  return evaluateInterestRatePriorities(
    offerSortedItemA,
    offerSortedItemB,
    combination,
  );
};

export const getInterestPriorities = (type: string) => {
  switch (type) {
    case LOAN_TYPES.installment:
      return 'INSTALLMENT';
    default:
      return 'OTHER';
  }
};

export const evaluateInterestRatePriorities = (
  offerSortedItemA: OfferSorted,
  offerSortedItemB: OfferSorted,
  combination: string,
) => {
  switch (combination) {
    case 'INSTALLMENT_OTHER':
      return -1;
    case 'OTHER_INSTALLMENT':
      return 1;
    default:
      // case 'OTHER_OTHER'
      return offerSortedItemA.id - offerSortedItemB.id;
  }
};
