import { OfferSorted } from 'lib/offers/types';
import { getEffectiveInterestRate } from './filterOffers';

export const sortOffersByEffectiveInterestRate = (
  offers: Record<string, OfferSorted> | undefined,
) => {
  if (!offers) {
    return [];
  }

  return Object.keys(offers)
    .reduce((state: OfferSorted[], key) => {
      state.push(offers[key]);
      return state;
    }, [])
    .sort((offerSortedItemA, offerSortedItemB) =>
      sortProducts(offerSortedItemA, offerSortedItemB),
    );
};

const sortProducts = (
  offerSortedItemA: OfferSorted,
  offerSortedItemB: OfferSorted,
) =>
  evaluateInterestRate(
    offerSortedItemA,
    offerSortedItemB,
    getEffectiveInterestRate(offerSortedItemA) -
      getEffectiveInterestRate(offerSortedItemB),
  );

export const evaluateInterestRate = (
  offerSortedItemA: OfferSorted,
  offerSortedItemB: OfferSorted,
  diff: number,
) => {
  switch (diff) {
    case 0:
      return offerSortedItemA.productOrder - offerSortedItemB.productOrder;
    default:
      return diff;
  }
};
