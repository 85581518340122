import { getSessionStorage } from 'lib/storage/storage';

// tries to get the subid from a voucher code if one is present
export const getSubIdFromStoredVoucherCode = () => {
  const voucherCode = getSessionStorage('FC_VOUCHER_CODE');
  const voucherCampaign = getSessionStorage('FC_VOUCHER_CAMPAIGN');
  const subId =
    voucherCode && voucherCampaign
      ? voucherCode + '000000' + voucherCampaign
      : undefined;
  return subId;
};
