import { IBestOffer } from 'contexts/BestOfferContext';
import { LoanSelectionProps } from 'contexts/LoanSelectionContext';
import { filterOffersByLoanSelection } from 'lib/best-offer/filterOffers';
import { pickBestOfferForEachBank } from 'lib/best-offer/pickOffers';
import { groupAndPrefixOffersByBank } from 'lib/best-offer/reduceOffers';
import { sortOffersByEffectiveInterestRate } from 'lib/best-offer/sortOffers';
import { Offer, OfferSorted } from 'lib/offers/types';

const AMOUNT_THRESHOLD_FOR_REAL_ESTATE = 50000;
const BIGGEST_AMOUNT = 5000000;

export const filterBestOffer = (
  offers: Offer[],
  loanSelection: LoanSelectionProps,
) => {
  // CAT-2355: We should no return any bestOffer if the amount is >= 50K
  // and the purpose is 'real_estate_financing'
  const isBigRealEstateAmount =
    loanSelection.amount >= AMOUNT_THRESHOLD_FOR_REAL_ESTATE &&
    loanSelection.purpose === 'real_estate_financing';
  // CAT-2328 No best offer for amounts over 5M whatsoever
  const isOverFiveMillion = loanSelection.amount > BIGGEST_AMOUNT;

  if (isBigRealEstateAmount || isOverFiveMillion) {
    return null;
  }

  const deepClonedLoanSelection = JSON.parse(JSON.stringify(loanSelection));
  const deepClonedOffers = JSON.parse(JSON.stringify(offers));

  const offersForLoanSelection = filterOffersByLoanSelection(
    deepClonedOffers,
    deepClonedLoanSelection,
  );

  const groupedOffers = groupAndPrefixOffersByBank(
    deepClonedLoanSelection.amount,
    deepClonedLoanSelection.term,
    offersForLoanSelection,
  );

  const bestOffersPerBank = pickBestOfferForEachBank(
    deepClonedLoanSelection.purpose,
    groupedOffers as Record<string, OfferSorted[]>,
  );

  const sortedBestOffers = sortOffersByEffectiveInterestRate(
    bestOffersPerBank as Record<string, OfferSorted>,
  );

  const bestOffer = sortedBestOffers[0];

  return bestOffer as IBestOffer;
};
