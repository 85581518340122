import {
  encodeUrlFromParts,
  getBaseUrlFromUrl,
  getQueryParamsFromUrl,
} from 'lib/helpers/urlUtils';
import {
  removeSessionStorage,
  setLocalStorage,
  getLocalStorage,
} from 'lib/storage/storage';
import { STORAGE_CONSTANTS } from 'lib/constants/storage';
import { track } from 'lib/tracking/helper';
import { TrackingEvents } from 'lib/tracking/events';

export const FUNNEL_URLS = {
  application: `/antrag`,
  realEstate: '/baufinanzierungsrechner',
};

/**
 * We get save the imod iframe url for later use and create a stub object for
 * imod to base their resume on. Note that the resume paramter on the url is depracted
 */
export const redirectToRegroute = (iframeUrl: string, formConfig?: string) => {
  if (!iframeUrl) {
    track(TrackingEvents.BS_NOTIFY, {
      error: new Error('IMOD redirect attempted without iframe url'),
      severity: 'error',
      context: 'IMOD redirect attempted without iframe url',
    });
  }

  removeSessionStorage(STORAGE_CONSTANTS.iModCurrentNavigation);

  try {
    const baseUrl = getBaseUrlFromUrl(iframeUrl);
    const queryParams: Record<string, string> = {
      ...getQueryParamsFromUrl(iframeUrl),
      ...(formConfig && { formConfig }),
    };

    const rawImodTraversalData = getLocalStorage(
      STORAGE_CONSTANTS.iModCurrentTraversalData,
    );
    const iModCurrentTraversalData = rawImodTraversalData
      ? JSON.parse(rawImodTraversalData)
      : {};

    const iframeUrlWithParams = encodeUrlFromParts(baseUrl, {
      ...queryParams,
    });
    setLocalStorage(STORAGE_CONSTANTS.iframeUrl, iframeUrlWithParams);

    setLocalStorage(
      STORAGE_CONSTANTS.iModCurrentTraversalData,
      JSON.stringify({
        ...iModCurrentTraversalData,
        resumeHash: queryParams.resume,
      }),
    );
  } catch (e) {
    // no parsable url supplied, we skip polluting the iModCurrentTraversalData // NOTE: set localStorage in any case to keep old behavior even in an error case
    setLocalStorage(STORAGE_CONSTANTS.iframeUrl, iframeUrl);
  }

  const currentQueryParams = getQueryParamsFromUrl(window.location.href);
  const currentBaseUrl = window.location.origin;

  const queryParams = {
    ...currentQueryParams,
    ...(formConfig && { formConfig }),
  };

  const regRouteUrl = encodeUrlFromParts(
    `${currentBaseUrl}${FUNNEL_URLS.application}`,
    queryParams,
  );
  window.location.assign(regRouteUrl);
};
