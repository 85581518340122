export const replaceSpaces = (str: string) => str.replace(/\s/g, '');

export const parseToInt = (
  value: string | number,
  replVal: string = '',
  radix = 10,
) => {
  if (typeof value === 'number') return value;

  if (typeof value === 'string') {
    const result = parseInt(
      typeof value === 'string' ? value.replace(replVal, '') : value,
      radix,
    );
    return isNaN(result) ? 0 : result;
  }
  return 0;
};
