/**
 * Dynamic template Strings
 * eg: format('This is ${0} a value. This also: $(1)', 'really', 'value')
 */
export const formatTemplateString = (
  inputTemplate: string,
  ...args: string[]
) =>
  args.reduce(
    (currentString, value, index) =>
      currentString.replace(new RegExp(`\\{${index}\\}`, 'g'), value),
    String(inputTemplate),
  );
