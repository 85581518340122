import { fetchWithTimeout } from 'lib/fetch/fetchWithTimeout';
import { track } from 'lib/tracking/helper';
import { TrackingEvents } from 'lib/tracking/events';

export const postPaasData = async (data: any) => {
  const paasUrl = process.env.NEXT_PUBLIC_PAAS_URL;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  try {
    const response = await fetchWithTimeout(`${paasUrl}/prefill`, {
      credentials: 'include',
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });

    if (!response) {
      throw new Error('No response while trying to post data to PAAS');
    }

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(
        `Received error response while trying to post data to PAAS. Status: ${response.status}`,
      );
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    track(TrackingEvents.BS_NOTIFY, {
      error,
      metaData: {
        body: JSON.stringify(data),
        headers,
        referrer: document.referrer,
      },
      context: 'PAAS communication failed',
      severity: 'error',
    });

    return false;
  }
};
