import { useCallback, useEffect, useState } from 'react';
import { STORAGE_CONSTANTS } from 'lib/constants/storage';
import { getLocalStorage, setLocalStorage } from 'lib/storage/storage';

const getResumeHashFromStore = () => {
  const imodCurrentTraversalData = getLocalStorage(
    STORAGE_CONSTANTS.iModCurrentTraversalData,
  );
  if (!imodCurrentTraversalData) return '';
  const resumeHash = JSON.parse(imodCurrentTraversalData).resumeHash;
  return resumeHash;
};

const setResumeHashToStore = (hash: string) => {
  if (!hash) return;
  const imodCurrentTraversalData = getLocalStorage(
    STORAGE_CONSTANTS.iModCurrentTraversalData,
  );
  if (!imodCurrentTraversalData) return;
  const traversalData = JSON.parse(imodCurrentTraversalData);
  traversalData.resumeHash = hash;
  const stringifiedTraversalData = JSON.stringify(traversalData);
  setLocalStorage(
    STORAGE_CONSTANTS.iModCurrentTraversalData,
    stringifiedTraversalData,
  );
};

export const useResumeHash = () => {
  const [resumeHash, setResumeHashState] = useState('');

  useEffect(() => {
    const hash = getResumeHashFromStore();
    hash && setResumeHashState(hash);
  }, []);

  const setResumeHash = useCallback((hash: string) => {
    setResumeHashToStore(hash);
    setResumeHashState(hash);
  }, []);

  return { resumeHash, setResumeHash };
};
