
import { postPaasData } from './fetchHelper/postPaasData';
import { IPaasV2WithoutDebtorsData } from './useFetchPaas';

// TODO: Correct typing
export const ensureErrorFieldName = (errors: any) =>
  errors.map((error: any) => error.fieldName || 'unknownField');

export const fetchPaas = async (encodedData: IPaasV2WithoutDebtorsData) => {

  if (!encodedData) return false;

  try {
    const paasResponse = await postPaasData(encodedData);

    if (!paasResponse) {
      return false;
    }

    const errors = ensureErrorFieldName(paasResponse.errors);

    return {
      ...paasResponse,
      errors,
      hasError: Boolean(errors.length),
    };
  } catch (e) {
    // last ditch and circumvent unhandled promise rejection
    return false;
  }
};
