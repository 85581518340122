import { styled } from '@mui/material/styles';

export const Skeleton = styled('div')(({ theme }) => ({
  width: '100%',
  height: 56,
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  position: 'relative',
  backgroundColor: 'rgba(0, 0, 0, 0.06)',

  transition: 'background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  borderBottom: '1px solid rgba(0, 0, 0, 0.54)',

  [theme.breakpoints.up('lg')]: {
    width: 360,
  },
}));

export const InputFieldSkeleton = () => {
  return <Skeleton />;
};
