import { getSessionStorage } from 'lib/storage/storage';
import { STORAGE_CONSTANTS } from 'lib/constants/storage';

export const getBid = () => {
  // NOTE: getSessionStorage will return false if no accessible
  const id = getSessionStorage(STORAGE_CONSTANTS.browserId);
  return id || null;
};

export const getUrlParamsForImod = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const refId = queryParams.get('refId');
  const subId = queryParams.get('subId');
  // originally a cafe function, we just route it through
  // FCID could be used as a fallback value
  const sid = queryParams.get('sid');
  const backUrl = queryParams.get('backUrl');
  // "da" seems no longer being used, so we no longer support it

  return {
    refId,
    subId,
    sid,
    backUrl,
  };
};
