import { LoanSelectionProps } from 'contexts/LoanSelectionContext';
import { Offer, OfferSorted } from 'lib/offers/types';

export const getEffectiveInterestRate = (offer: OfferSorted) =>
  offer.condition?.effectiveInterestRate;

export const getMonthlyRate = (offer: OfferSorted) => offer?.monthlyRate || 0;

export const filterOffersByLoanSelection = (
  offers: Offer[] | undefined,
  loanSelection: LoanSelectionProps,
) => {
  if (!offers) {
    return [];
  }

  const deepClonedOffers = JSON.parse(JSON.stringify(offers));

  const filteredOffers = deepClonedOffers.filter((offer: Offer) => {
    const conditions = offer.conditions.filter(
      (condition) =>
        loanSelection.amount >= condition.amountMin &&
        loanSelection.amount <= condition.amountMax &&
        loanSelection.term >= condition.termFrom &&
        loanSelection.term <= condition.termUntil &&
        offer.purposes.indexOf(loanSelection.purpose.toLowerCase()) > -1,
    );
    offer.conditions = conditions;
    return offer.conditions.length;
  });

  return filteredOffers;
};
