import { getUrlParamsForImod } from './imodParamsHelper/paramsHelper';
import { getBackLinkFromHref, setBackLink } from './backLink';
import { getSubIdFromStoredVoucherCode } from './imodParamsHelper/getSubIdFromStoredVoucherCode';

import { fetchPaas } from './fetchPaas';
import { useResumeHash } from './useResumeHash';
import { useLoanSelection } from 'contexts/LoanSelectionContext';
import { parseToInt } from './paasSchema/encodingHelpers';

const FFG_AFFILIATE_ID = 1000;

interface IPaasSystemData {
  advertisementId: string | undefined;
  affiliateId: number | undefined;
  clientUuid: string;
  configurationId: string | undefined;
  externalId: string | undefined;
  ipAddress: string | undefined;
  referer: string | undefined;
  refId: string | undefined;
  subId: string | undefined;
  userAgent: string | undefined;
  leadId: string | undefined;
}

interface IPaasLoanSelectionData {
  amount: number;
  term: number;
  purpose: string;
}
export interface IPaasV2WithoutDebtorsData {
  data: {
    common: IPaasLoanSelectionData;
    system: IPaasSystemData;
  };
  resumeHash: string | undefined;
}


/**
 * We have a staged fallback if refid is present or not
 * @param advertisementId
 * @param refId
 * @returns
 */
export const getAdvertisementId = (
  advertisementId: string | undefined,
  refId: string | null,
) => {
  if (refId) return refId;
  return advertisementId || process.env.NEXT_PUBLIC_ADVERTISMENT_ID_DEFAULT;
};

/**
 * We have a staged fallback if refid is present or not
 * @param refId
 * @returns
 */
export const getAffiliateId = (
  refId: string | null,
) => {
  if (refId) return undefined;
  return process.env.AFFILIATE_ID || FFG_AFFILIATE_ID;
};

export const useFetchPaas = (
  advertisementId?: string,
) => {
  const { resumeHash, setResumeHash } = useResumeHash();

  // Get loan details from context
  const { loanSelection } = useLoanSelection();

  const amount = loanSelection.amount;
  const term = loanSelection.term;
  const purpose = loanSelection.purpose;

  const sendPaasData = async () => {
    const { refId, subId: subIdFromUrl } = getUrlParamsForImod();
    const backUrl = getBackLinkFromHref();

    // Get subId from url or from stored voucher code (if any)
    const subIdFromVoucher = getSubIdFromStoredVoucherCode();
    const subId = subIdFromVoucher ? subIdFromVoucher : subIdFromUrl;
    const clientUuid = process.env.NEXT_PUBLIC_CLIENT_UUID;
    const configurationId = process.env.NEXT_PUBLIC_ADVERTISMENT_ID_DEFAULT;
    const advertisementIdWithFallback = getAdvertisementId(advertisementId, refId)
    const affiliateId = getAffiliateId(refId)

    // Construct the base payload and only add the filled-in values
    const paasPayload = {
      data: {
        common: {
          amount: parseToInt(amount),
          term: parseToInt(term),
          purpose: purpose.toUpperCase(),
        },
        system: {
          clientUuid: clientUuid,
          advertisementId: advertisementIdWithFallback,
          affiliateId: affiliateId,
          configurationId: configurationId,
        },
      },
    } as IPaasV2WithoutDebtorsData;

    if (subId) paasPayload.data.system.subId = subId;
    if (refId) paasPayload.data.system.refId = refId;
    if (resumeHash) paasPayload.resumeHash = resumeHash;

    const paasResponse = await fetchPaas(paasPayload);

    // Response looks like
    // {
    //   "status": 201,
    //   "result": {
    //     "url": "https://taurine.finanzcheck-shared.de/?resume=a6b831ef-37f2-4b59-812c-75139539e5e7",
    //     "resumeHash": "a6b831ef-37f2-4b59-812c-75139539e5e7"
    //   },
    //   "errors": [],
    //   "hasError": false
    // }

    const isResponseValid = paasResponse && paasResponse.hasError === false;
    if (!isResponseValid) return false;
    if (paasResponse?.result?.resumeHash) {
      setResumeHash(paasResponse.result.resumeHash);
    }

    setBackLink(backUrl);

    return paasResponse;
  };

  return { sendPaasData };
};


export const useFetchPaasWithoutDebtors = (advertisementId?: string) =>
  useFetchPaas(advertisementId);
