const PATHNAMES_WITH_ADVERTISMENT_ID_COOP = [
  '/aktion',
  '/kreditantrag',
  '/coop',
];

const getAdvertisementId = (path: string) => {
  if (
    PATHNAMES_WITH_ADVERTISMENT_ID_COOP.some((pathnameWithAdvertismentIdCoop) =>
      path.startsWith(pathnameWithAdvertismentIdCoop),
    )
  ) {
    return process.env.NEXT_PUBLIC_ADVERTISMENT_ID_COOP;
  }

  return process.env.NEXT_PUBLIC_ADVERTISMENT_ID_DEFAULT;
};

export { getAdvertisementId };
