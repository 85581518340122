import { formatTemplateString } from 'lib/helpers/formatTemplateString';
import { AMOUNT_MAX, AMOUNT_MIN } from 'lib/constants/offerFilter';
import { redirectToRegroute } from 'lib/regroute/redirectToRegroute';
import { formatNumber } from 'lib/helpers/formatNumber';
import {
  AMOUNT_MAX_SMALL_HOME_RELATED,
  AMOUNT_MAX_NON_MORTGAGE,
  AMOUNT_MAX_SMALL_MORTGAGE,
} from 'lib/constants/offerFilter';
import { TrackingEvents } from 'lib/tracking/events';
import { track } from 'lib/tracking/helper';
import { getQueryParamsFromUrl, encodeUrlFromParts } from './urlUtils';
import { AFFILIATE } from 'lib/constants/registrationRoutes';
import { PageConfigContextType } from 'contexts/PageConfigContext';
import {
  getEffectiveInterestRate,
  getMonthlyRate,
} from 'lib/best-offer/filterOffers';
import { getCookie } from 'lib/cookies/helper';
import { SESSION_COOKIE_NAME } from 'lib/session/session';

interface ICreditParams {
  amount: number;
  term: number;
  purpose: string;
}

/**
 * check if the amount is within boundaries
 *
 * @param {*} creditParams
 * @returns {string|null} error text or null
 */
export const getFormErrors = (creditParams: ICreditParams) => {
  const { amount } = creditParams;

  if (amount < AMOUNT_MIN) {
    return formatTemplateString(
      'Der Mindestbetrag muss mindestens {0} € sein',
      formatNumber(AMOUNT_MIN),
    );
  }

  if (amount > AMOUNT_MAX) {
    return formatTemplateString(
      'Der Höchstbetrag darf maximal {0} € sein',
      formatNumber(AMOUNT_MAX),
    );
  }
};

/**
 * send data to paas and use return value url as redirect target
 */
export const createRedirectImodAntrag =
  (sendPaasData: Function, formConfig?: string) => async () => {
    const paasResponse = await sendPaasData({ termsAccepted: false });
    if (!paasResponse) {
      return;
    }

    redirectToRegroute(paasResponse?.result?.url, formConfig);
  };

export const redirectToAffiliateAntrag = (creditParams: ICreditParams) => {
  const { amount, term, purpose } = creditParams;
  const currentQueryParams = getQueryParamsFromUrl(window.location.href);
  const affiliateUrl = encodeUrlFromParts(
    process.env.NEXT_PUBLIC_AFFILIATE_ANTRAG_URL!,
    {
      ...currentQueryParams,
      amount: amount.toString(),
      duration: term.toString(),
      purpose: purpose.toUpperCase(),
      advertisementId: process.env.NEXT_PUBLIC_ADVERTISMENT_ID_AFFILIATE!,
    },
  );
  window.location.assign(affiliateUrl);
};

/**
 * redirect to RealEstate
 */
export const redirectRealEstate = (creditParams: ICreditParams) => {
  const { amount, term } = creditParams;
  const mortgageUrl = encodeUrlFromParts(
    process.env.NEXT_PUBLIC_MORTGAGE_URL!,
    { p: 'angebote', amount: amount.toString(), term: term.toString() },
  );
  window.location.assign(mortgageUrl);
};

/**
 * submit handler for Offerfilter
 */
export const getHandleSubmit =
  (
    creditParams: ICreditParams,
    sendPaasData: any,
    pageConfigContext: PageConfigContextType,
    bestOffer: any,
  ) =>
  async (event: any) => {
    event.preventDefault();

    const { amount, term, purpose } = creditParams;
    const { pageConfig } = pageConfigContext;

    track(TrackingEvents.LOANSELECTOR_FORM_ONSUBMIT);

    if (getFormErrors(creditParams)) {
      return;
    }

    // check if we are on affiliate route
    // https://kreditvergleich.finanzcheck.de/coop/antrag
    const isAffiliate = () => pageConfig.registrationRoute === AFFILIATE;

    // check for conditon then forward to url baufinanzierungsrechner
    const isMortgage = (creditParams: ICreditParams) => {
      const { amount, purpose } = creditParams;

      const mortgage =
        amount > AMOUNT_MAX_NON_MORTGAGE ||
        (amount >= AMOUNT_MAX_SMALL_MORTGAGE &&
          purpose === 'real_estate_financing') ||
        (amount > AMOUNT_MAX_SMALL_HOME_RELATED &&
          (purpose === 'renovation' ||
            purpose === 'furniture' ||
            purpose === 'move'));
      return mortgage;
    };

    const isMortgageRedirect = !isAffiliate() && isMortgage(creditParams);
    if (isMortgageRedirect) {
      redirectRealEstate(creditParams);
      return;
    }

    track(TrackingEvents.NOTIFY_CRITEO, {
      amount,
      term,
      purpose,
      effectiveInterestRate: bestOffer && getEffectiveInterestRate(bestOffer),
      monthlyRate: bestOffer && getMonthlyRate(bestOffer),
      // example: getLegalText(bestOffer), we are no longer showing the legal text
    });

    track(
      TrackingEvents.LOANSELECTOR_FORM_ONSUBMIT,
      {
        amount,
        term,
        purpose,
      },
      {
        context: {
          Optimizely: {
            userId: getCookie(SESSION_COOKIE_NAME),
          },
        },
      },
    );

    // Check for conditon then forward to affiliate regroute if applicable
    // https://kreditvergleich.finanzcheck-shared.de/coop/antrag
    if (isAffiliate()) {
      redirectToAffiliateAntrag(creditParams);
      return;
    }

    // set the loanSelection into url bar for history purposes
    const url = new URL(window.location.href);
    url.searchParams.set('amount', amount.toString());
    url.searchParams.set('term', term.toString());
    url.searchParams.set('purpose', purpose);
    window.history.replaceState({}, '', url.toString());

    const { formConfig } = pageConfig;

    // Base case - we progress into normal RegRoute via Imod
    const redirectImod = createRedirectImodAntrag(sendPaasData, formConfig);
    await redirectImod();
  };
