import { useState, useCallback } from 'react';

/**
 * formhelper hook that prevents multiple calls to a submitHandler
 * @returns [isSubmitting, handleSubmit]
 */
export const useSubmit = (submitFn: Function) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: any) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    await submitFn(event);
    setIsSubmitting(false);
  };

  const handleSubmitMemo = useCallback(handleSubmit, [isSubmitting, submitFn]);

  return [isSubmitting, handleSubmitMemo];
};
