import { LoanSelectionProps, InputNames } from 'contexts/LoanSelectionContext';
import { Offer } from 'lib/offers/types';

// TODO: add a test.
export const groupAndPrefixOffersByBank = (
  amount: LoanSelectionProps[InputNames.Amount],
  term: LoanSelectionProps[InputNames.Term],
  offers: Offer[],
) => {
  if (!offers) {
    return [];
  }

  return offers.reduce((state: Record<string | number, any>, offer) => {
    const {
      purposes,
      conditions: [condition],
      isBalloonLoan,
      ...restOffer
    } = offer;

    const bankId = offer.bankId || -1;
    state[bankId] = [
      ...(state[bankId] || []),
      {
        ...restOffer,
        condition,
        monthlyRate: getMonthlyRate(
          amount,
          term,
          condition.effectiveInterestRate,
        ),
      },
    ];

    return state;
  }, {});
};

export const calcMonthlyRate = (
  amount: LoanSelectionProps[InputNames.Amount],
  term: LoanSelectionProps[InputNames.Term],
  interest: number,
) => {
  const base = (interest / 100 + 1) ** (1 / 12);
  return (
    Math.round(
      amount * (base ** term / (base ** term - 1)) * (base - 1) * 100,
    ) / 100
  );
};

export const getMonthlyRate = (
  amount: LoanSelectionProps[InputNames.Amount],
  term: LoanSelectionProps[InputNames.Term],
  interest: number,
) =>
  Math.round(
    (interest === 0 ? amount / term : calcMonthlyRate(amount, term, interest)) *
      100,
  ) / 100;
