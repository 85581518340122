import { setSessionStorage, getSessionStorage } from 'lib/storage/storage';
import { STORAGE_CONSTANTS } from 'lib/constants/storage';

const websiteBaseUrl = process.env.NEXT_PUBLIC_WEBSITE_BASE_URL;

export const setBackLink = (href: string) =>
  setSessionStorage(STORAGE_CONSTANTS.backUrl, href);

export const getBackLink = () =>
  getSessionStorage(STORAGE_CONSTANTS.backUrl) ||
  window.location.href ||
  websiteBaseUrl!;

export const getBackLinkFromHref = () =>
  window.location.href || websiteBaseUrl!;
